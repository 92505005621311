import './App.css';
import Nav from './Nav';
import AboutMe from './AboutMe';
import Projects from './Projects';
import Contact from './Contact';

function App() {
  return (
    <main>
        <Nav />
        <AboutMe />
        <Projects />
        <Contact />
    </main>
  );
}

export default App;
