import React from 'react';
import { Link } from 'react-scroll';
import './Nav.css';

const Nav = () => {
  return (
    <header className="nav-container">
      <Link to="home" smooth={true} duration={500} className="logo">
        <h1 className="brand">st</h1>
      </Link>
      <nav className="nav-links">
        <Link to="projects" smooth={true} duration={500} className="nav-link">
          Projects
        </Link>
        <Link to="contact" smooth={true} duration={500} className="nav-link">
          Contact
        </Link>
      </nav>
    </header>
  );
};

export default Nav;
