import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';

function ProjectItem({ name, description, repoLink, liveLink, iconUrl }) {
  return (
    <div className="project-item">
      <a href={liveLink} target="_blank" rel="noopener noreferrer">
        <img
          alt={`${name} icon`}
          loading="lazy"
          width="40"
          height="40"
          decoding="async"
          data-nimg="1"
          className="project-icon rounded-lg"
          style={{ color: 'transparent' }}
          src={iconUrl}
        />
      </a>
      <div className="project-details">
        <h3 className="project-name">{name}</h3>
        <h3 className="project-description">{description}</h3>
      </div>
      <div className="project-links">
        <a href={liveLink} target="_blank" rel="noopener noreferrer">
          <LaunchIcon />
        </a>
        <a href={repoLink} target="_blank" rel="noopener noreferrer">
          <GitHubIcon/>
        </a>
      </div>
    </div>
  );
}

export default ProjectItem;
