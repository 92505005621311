import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
  return (
    <section className="about-me-container">
      <h1 className="about-me-title">Hi there, I'm Shawn 👋🏼 <br /><span className="about-me-subtitle">A software developer based in Toronto.</span></h1>
      <p className="about-me-text">I’m Shawn, a developer based in Toronto, Canada. I build intuitive fullstack experiences, mostly for small businesses or just things that catch my interest.
      </p>
    </section>
  );
};

export default AboutMe;
