import React from 'react';
import ProjectItem from './ProjectItem';
import iconOne from './images/icon.png';
import iconTwo from './images/icon2.png';
import iconThree from './images/icon3.png';
import './Projects.css';

function Projects() {
  return (
    <section className="projects-container" id="projects">
      <h2 className="projects-title">Projects</h2>
      <div className="projects-list">
        <ProjectItem
          name="Invi"
          description="Streamlined management solution for inventory and invoices."
          iconUrl={iconThree}
          liveLink="https://invi-app.netlify.app/"
          repoLink="https://github.com/shawn-thomas/invi-app-backend"
        />
        {/* <ProjectItem
          name="Jobly"
          description="React Application for displaying companies and their associated jobs with authentication and profile editing."
          iconUrl={iconTwo}
          liveLink="https://flagdb.com"
          repoLink="https://github.com/yourusername/jobly-repo"
        /> */}
        <ProjectItem
          name="Warbler"
          description="A server-side social media app where users can view messages, post messages, and like one another's posts."
          iconUrl={iconOne}
          liveLink="https://warbler-st.onrender.com/"
          repoLink="https://github.com/shawn-thomas/r32-warbler"
        />
      </div>
    </section>
  );
}

export default Projects;
