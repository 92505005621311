// Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section className="contact-container" id="contact">
      <h2 className="contact-title">Contact</h2>
      <div className="contact-info">
        <ContactItem label="Email" value="shawn.thomas1@outlook.com" />
        <ContactItem label="GitHub" value="https://github.com/shawn-thomas" />
        <ContactItem label="LinkedIn" value="https://www.linkedin.com/in/shawn-thomas1/" />
      </div>
    </section>
  );
};

const ContactItem = ({ label, value }) => {
  return (
    <div className="contact-item">
      <span className="contact-label">{label}</span>
      <a className="contact-value" href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    </div>
  );
};

export default Contact;
